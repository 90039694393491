import React from 'react';
import Image from "react-bootstrap/Image";

import author from '../assets/author.jpg';

function Author() {
    return (
        <section id="author-section" className="author-section section theme-bg-primary py-5">
            <div className="container py-3">
                <div className="author-profile text-center mb-5">
                    <Image style={{width: '200px', height: '200px'}} src={author} roundedCircle alt="Photo of author Diane Lyons Frasco"/>
                </div>
                <h2 className="section-heading text-center text-white mb-3" style={{fontFamily: 'Dancing Script'}}>About The Author</h2>
                <div className="author-bio single-col-max mx-auto">
                    <p>Diane Lyons – Frasco is first and foremost a Mother of two fantastic, wonderful, loving sons, who helped her with this book, using their God given talents.</p>
                    <p>Diane is a mystic, and a victim soul. She uses her gifts to help others. Diane holds two degrees and four diplomas. She is a 1992 graduate of “Our Lady of the Elms College” in Southwick, Massachusetts. She holds a BA in Psychology/ Theology; also she holds an AS in Machine and Tool Design/ Mechanical Engineering from Springfield Technical Community College, in Springfield, Massachusetts.</p>
                </div>
            </div>
        </section>
    );
}

export default Author;
