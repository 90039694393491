import React from 'react';

function What() {
    return (
        <section id="benefits-section" className="benefits-section theme-bg-light-gradient py-5">
            <div className="container py-5">
                <h2 className="section-heading text-center mb-3" style={{fontFamily: 'Dancing Script'}}>What Will You Get From This Book?</h2>
                <div className="section-intro single-col-max mx-auto text-center mb-5">You will gain knowledge, wisdom and a greater understanding of the Spiritual and Supernatural life.</div>
                <div className="row text-center">
                    <div className="item col-12 col-md-6 col-lg-3">
                        <div className="item-inner p-3 p-lg-4">
                            <div className="item-header mb-3">
                                <div className="item-icon"><i className="fas fa-cross"/></div>
                                <h3 className="item-heading">Mistakes</h3>
                            </div>
                            <div className="item-desc">
                                Don't make the same mistakes in life that the author experienced.
                            </div>
                        </div>
                    </div>
                    <div className="item col-12 col-md-6 col-lg-3">
                        <div className="item-inner p-3 p-lg-4">
                            <div className="item-header mb-3">
                                <div className="item-icon"><i className="fas fa-church"/></div>
                                <h3 className="item-heading">Life Lessons</h3>
                            </div>
                            <div className="item-desc">
                                Includes general and spiritual life lessons explained in her own life experiences in a relatable way.
                            </div>
                        </div>
                    </div>
                    <div className="item col-12 col-md-6 col-lg-3">
                        <div className="item-inner p-3 p-lg-4">
                            <div className="item-header mb-3">
                                <div className="item-icon"><i className="fas fa-dove"/></div>
                                <h3 className="item-heading">Advice</h3>
                            </div>
                            <div className="item-desc">
                                Author explains very good, practical advice for daily use.
                            </div>
                        </div>
                    </div>
                    <div className="item col-12 col-md-6 col-lg-3">
                        <div className="item-inner p-3 p-lg-4">
                            <div className="item-header mb-3">
                                <div className="item-icon"><i className="fas fa-fish"/></div>
                                <h3 className="item-heading">Global Warming</h3>
                            </div>
                            <div className="item-desc">
                                Heaven gives the author a message about global warming. It is the true reason why the Icebergs are melting at such a rapid rate.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
);
}

export default What;
