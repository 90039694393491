import React from 'react';
import {captureLink} from "../analytics";
import {Button} from "react-bootstrap";

function Who() {
    return (
        <section id="audience-section" className="audience-section py-5">
            <div className="container">
                <h2 className="section-heading text-center mb-4" style={{fontFamily: 'Dancing Script'}}>Who This Book Is For</h2>
                <div className="section-intro single-col-max mx-auto text-center mb-5">
                    This book is written for everyone. It was written in a simple manner to appeal, especially for the youth. The Holy Spirit welcomes all souls to read it and enjoy it.
                </div>
                <div className="text-center">
                    <Button primary onClick={() => captureLink('https://checkout.square.site/merchant/ML7232HNJPG9H/checkout/K75G4F3IK7KP22AGR2DM25X3', 'square')}>Get Your Copy Today</Button>
                </div>
            </div>
        </section>
);
}

export default Who;
