import React, {Fragment} from 'react';
import Top from "./components/Top";
import What from "./components/What";
import Who from "./components/Who";
import Author from "./components/Author";


function App() {
  return (
  <Fragment>
    <Top/>
    <What/>
    <Who/>
    <Author/>
  </Fragment>
  );
}

export default App;
